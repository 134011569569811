export const firebaseConfig = {
    apiKey: "AIzaSyB5RCqLim4FSabW-hgjNQIIUW6ObQ-dnmA",
    authDomain: "skipwork-cal.firebaseapp.com",
    databaseURL: "https://skipwork-cal.firebaseio.com",
    projectId: "skipwork-cal",
    storageBucket: "skipwork-cal.appspot.com",
    messagingSenderId: "998995946936",
    appId: "1:998995946936:web:6e3c4958803aa70265f34d",
    measurementId: "G-G1HFRH2YNL"
};

import React, { Fragment } from 'react';

import moment from "moment-timezone";
import Main from './component/Main';
import YearView from './component/YearView';
import SvgCalendarView from './component/SvgCalendarView';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import firebase from './firebase/';
import { Provider } from 'react-redux'
import store from './models/store.js';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore

const rrfProps = {
  firebase,
  config: {
    // userProfile: 'users',
    // useFirestoreForProfile: true
  },
  dispatch: store.dispatch,
  createFirestoreInstance
}

function App() {

  const year = moment().year() + 1;
  console.log("year: " + year);
  return (
    <Fragment>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Router>
            <Switch>
              <Route path="/svg" render={() => <SvgCalendarView />} />
              <Route path='/b6' render={() => <YearView year={year} baseClass="B6" showCoverPage={true} />} />
              <Route path='/post' render={() => <YearView year={year} baseClass="Post" showCoverPage={true} />} />
              <Route path='/gb6p' render={() => <YearView year={year} baseClass="GB6P" showCoverPage={true} />} />
              <Route path='/gb6' render={() => <YearView year={year} baseClass="GB6" showCoverPage={true} />} />
              <Route path='/b6en' render={() => <YearView lang="en" year={year} baseClass="B6" showCoverPage={true} />} />
              <Route path='/edit/:fromDate?' component={Main} />
              <Route path='/cal' render={() => <YearView year={year} baseClass="Responsive" showCoverPage={false} />} />
              <Route path='/en' render={() => <YearView lang="en" year={year} baseClass="ResponsiveEn" showCoverPage={false} />} />
              <Route path='/' render={() => <YearView year={year} baseClass="Responsive" showCoverPage={false} />} />
            </Switch>
          </Router>
        </ReactReduxFirebaseProvider>

      </Provider>
    </Fragment  >
  );
}

export default App;

import React, { useState } from 'react';

import dayjs from 'dayjs';
import { HolidayForDate } from '../data/holidays.js';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase';
import { useFirestore } from 'react-redux-firebase'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'

import Template5 from '../cal05.svg';
import Template6 from '../holiday_template6.png';
import TitleImage from '../cover_title.svg';

const MonthTitle = ({ lang, year, month, classes }) => {
    return (
        <div className={classes("MonthTitleWrapper")}>
            <img src={TitleImage} alt="title" className={classes("monthTitleImage")} />

            {/* <div className={classes("MonthTitle Block")}> */}
            <span className={classes("MonthTitle Label Year")}>{year}</span>
            <span className={classes("MonthTitleLabelYearUnit")}>年</span>
            <span className={classes("MonthTitle Label Month")}>{month}</span>
            {/* </div> */}
        </div>
    );
}

// DOW of the leftmost column.
const firstColumnDOW = 0;
const dowStrsJa = [
    "日",
    "月",
    "火",
    "水",
    "木",
    "金",
    "土",
];
const dowStrsEn = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];

const Component = ({
    holidays,
    year, month, classes,
    lang = "ja"
}) => {
    // console.log(holidays);

    // Both are inclusive.
    const fromDate = dayjs(new Date(year, month - 1, 1));
    const toDate = dayjs(new Date(year, month, 1)).subtract(1, 'day');

    // Date of the top left cell.
    const topLeftCellDate = fromDate.subtract((fromDate.get('day') - firstColumnDOW + 7) % 7, 'day');
    const bottomLeftCellDate = toDate.subtract((toDate.get('day') - firstColumnDOW + 7) % 7, 'day');

    let weeks = [];
    let days = [];
    // days.push();
    for (let i = 0; i < 7; i++) {
        let classNames = ["DOWCell"];
        if (i === 0) { classNames.push("Red"); }
        weeks.push(
            <div key={"dow" + i} className={classes(classNames.join(" "))}>
                {/* TODO(ken): localize */}
                {lang === "en" ? dowStrsEn[i] : dowStrsJa[i]}
            </div>);
    }

    var holidayForDate = holidays.reduce(
        (map, h) => { map[dayjs(h.date).format("YYYY-MM-DD")] = h; return map; }, {});

    let numRows = 0;
    for (let rowStartDate = topLeftCellDate; !rowStartDate.isAfter(bottomLeftCellDate); rowStartDate = rowStartDate.add(7, 'day')) {

        for (let i = 0; i < 7; i++) {
            let d = rowStartDate.add(i, 'day');
            let dateID = d.format("YYYY-MM-DD");
            let holiday = holidayForDate[dateID];
            // console.log(dateID, holiday);
            const inBound = !d.isBefore(fromDate) && !d.isAfter(toDate);

            let dayCellClass = ["DayCell"];
            if (i === 0) {
                dayCellClass.push("Leftmost");
            }
            if (rowStartDate === topLeftCellDate) {
                dayCellClass.push("Topmost");
            }

            let dateLabelClass = ["DateLabel"];
            dateLabelClass.push(holiday ? "Holiday" : "Normal");
            if (!inBound) {
                dateLabelClass.push("OOB");
            }

            // TODO(ken): translation
            let name = "";
            let country = "";
            if (holiday) {
                switch (lang) {
                    case 'en':
                        name = holiday.originalName;
                        country = holiday.countryEn;
                        break;
                    default:
                        name = holiday.nameJa;
                        country = holiday.country;
                }
            }
            const unbreakableWords = [
                "記念日",
                "即位",
                "マンデー",
                "誕生日",
            ];
            for (let word of unbreakableWords) {
                name = name.replace(word, `<wbr>${word}`);
            }
            name = name.replace("記念日", "<wbr>記念日");
            if (!inBound) {
                name = "";
            }
            days.push(
                <div key={dateID} className={classes(dayCellClass.join(" "))}>
                    <div className={classes(dateLabelClass.join(" "))}
                    // onClick={(e) => { window.location.href = "edit/" + d.format("YYYYMMDD"); }}
                    >
                        {d.format("D")}
                    </div>
                    {/* <div className={classes("HolidayDesc Name")}>
                        {inBound && (holiday ? `${name}` : "")}
                    </div> */}
                    <div className={classes("HolidayDesc Name")} dangerouslySetInnerHTML={{ __html: name }} />
                    <div className={classes("HolidayDesc Country")}>
                        {inBound && (holiday ? `${country}` : "")}
                    </div>
                </div>);
        }
        numRows++;
    }

    let gridClass = ["DaysGrid"];
    if (numRows === 5) {
        gridClass.push("Five");
    } else {
        gridClass.push("Six");
    }

    let [showTemplate, setShowTemplate] = useState(false);

    return (
        <div className={classes("calendarParent")}
            onClick={e => {
                // alert(showTemplate);
                setShowTemplate(!showTemplate);
            }}>
            {/* <img src={Template5} alt="Nope" className={classes("overlayImage"
                + (showTemplate ? "" : " hidden")
            )}
            /> */}
            <div
                className={classes((showTemplate ? " hidden" : ""))}
            >
                <MonthTitle key={month} lang={lang} year={year} month={month} classes={classes} />
            </div>
            <div className={classes("calendarContainer"
                + (showTemplate ? " hidden" : "")
            )}
            >
                <div className={classes("WeekRow")} >
                    {weeks}
                </div>
                <div className={classes("StretchedWithMargin")}>
                    <div className={classes(gridClass.join(" "))}>
                        {days}
                    </div>
                </div>
            </div>
            {/* <img src={Template} alt="Nope" className={classes("overlayImage")} /> */}
            {/* <div className="DebugLeft"></div> */}
        </div>
    );
}

export default Component;

import React from 'react';

import dayjs from 'dayjs';
import CalendarView from './CalendarView.js';
import CoverView from './CoverView.js';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase';
import { useFirestore } from 'react-redux-firebase'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'

const mapStateToProps = state => {
  return {
    holidays: state.firestore.ordered.holiday,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    // doLogout: bindLogout(dispatch),
  }
}

const Component = ({
  lang,
  holidays,
  year, baseClass, showCoverPage
}) => {
  if (!holidays) {
    return "";
  }

  // let baseClass = "Post";
  function classes(moreClasses) {
    // return `${baseClass} ${moreClasses}`;
    return moreClasses;
  }

  const fromDate = dayjs(new Date(year, 1 - 1, 1));
  const toDate = dayjs(new Date(year + 1, 1 - 1, 1)).subtract(1, 'day');

  var holidayForDate = holidays.reduce(
    (map, h) => { map[dayjs(h.date).format("YYYY-MM-DD")] = h; return map; }, {});

  // let days = [];
  // for (let d = fromDate; !d.isAfter(toDate); d = d.add(1, 'day')) {
  //   let dateID = d.format("YYYY-MM-DD");
  //   let holiday = holidayForDate[dateID];

  //   if (holiday) {
  //     days.push(<div>
  //       {d.format("MM-DD")}<br />{holiday.nameJa}<br />{holiday.country}
  //       <hr />
  //     </div>);
  //   } else {
  //     days.push(<div>

  //       {d.format("MM-DD")} NOPE
  //     </div>)
  //   }
  // }

  // return (
  //   <div>
  //     {days}
  //   </div>
  // );

  let pages = [];
  if (showCoverPage) {
    // pages.push(<CoverView classes={classes} />);
  }
  // for (let month = 1; month <= 12; month++) 
  let month = 1;
  {
    var monthHolidays = holidays.filter(h => {
      var d = dayjs(h.date);
      return d.get("month") === month - 1;
    });
    pages.push(<CalendarView key={month} lang={lang} year={year} month={month} classes={classes} holidays={monthHolidays}></CalendarView>);
    // if (true) break;
  }
  return (
    <div>
      <link rel="stylesheet" type="text/css" href={`Cal${baseClass}.css`} />
      {pages}
    </div>
  );
}

export default compose(
  // withFirestore,
  connect(
    mapStateToProps,
    mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'holiday',
        // limit: 20,
        where: [
          ['representative', '==', true],
        ]
        // doc: id,
        // storeAs: 'pathsResult',
      }
    ]
  }
  ))(Component);

import React, { Fragment, useState } from 'react';

// import 'App.js';

import { makeStyles } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Link, AppBar, Toolbar, Typography, Card, CardContent, Button, TextField, Input, Chip, Icon, Radio,
  FormControl, InputLabel
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase';
import { useFirestore } from 'react-redux-firebase'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'

import dayjs from 'dayjs';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
  datePane: {
    padding: theme.spacing(1),
  },
  holidayPane: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  inputLabel: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  }
}));

const mapStateToProps = (state, ownProps) => {
  return {
  };
}
const mapDispatchToProps = dispatch => {
  return {
    // doLogout: bindLogout(dispatch),
  }
}

const AddHolidayPanel = ({
  date
}) => {
  const classes = useStyles();
  const firestore = useFirestore();

  const inCountry = React.createRef();
  const inCountryEn = React.createRef();
  const inOriginalName = React.createRef();

  const [isEditing, setEditing] = useState(false);

  if (!isEditing) {
    return <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<Icon>add_circle</Icon>}
        onClick={(e) => setEditing(true)}
      >
        Add
      </Button>
    </div>;
  }
  // const submitEdit = (newName) => {
  //   firestore.collection("holiday").doc(holiday.id)
  //     .update({
  //       nameJa: newName,
  //     });
  //   setEditing(false);
  // }
  // const submitForm = (e) => {
  //   e.preventDefault();
  //   submitEdit(nameJa.current.value);
  // }

  /**
   * country (ja)
   * countryEn (en)
   * date
   * nameJa
   * originalName
   */

  const submitForm = (e) => {
    // console.log("date:", date);
    // console.log("inCountry:", inCountry.current.value);
    // console.log("inCountryEn:", inCountryEn.current.value);
    // console.log("inOriginalName:", inOriginalName.current.value);

    firestore.add('holiday', {
      country: inCountry.current.value,
      countryEn: inCountryEn.current.value,
      date: date,
      originalName: inOriginalName.current.value,
    });
    // firestore.collection("holiday").doc(holiday.id)
    //   .update({
    //     nameJa: newName,
    //   });
    inCountry.current.value = "";
    inCountryEn.current.value = "";
    inOriginalName.current.value = "";
    setEditing(false);
  };

  return <Paper className={classes.holidayPane}>
    <div><Typography variant="h6">New holiday entry</Typography></div>
    <form
      onSubmit={submitForm} >

      <FormControl>
        <InputLabel htmlFor="inCountry" className={classes.inputLabel}>国名</InputLabel>
        <Input id="inCountry" inputRef={inCountry} required className={classes.input} />
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="inCountryEn" className={classes.inputLabel}>Country name</InputLabel>
        <Input id="inCountryEn" inputRef={inCountryEn} required className={classes.input} />
      </FormControl>

      <FormControl>
        <InputLabel htmlFor="inOriginalName" className={classes.inputLabel}>Holiday name</InputLabel>
        <Input id="inOriginalName" inputRef={inOriginalName} required className={classes.input} />
      </FormControl>

      <Button variant="contained" color="primary" onClick={submitForm}>Submit</Button>
    </form>
  </Paper >
};

export default AddHolidayPanel;

import React, { useState, useRef } from 'react';

import dayjs from 'dayjs';
import { HolidayForDate } from '../data/holidays.js';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase';
import { useFirestore } from 'react-redux-firebase'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'
// import { SvgLoader, SvgProxy } from 'react-svgmt';
// import { SvgLoader, SvgProxy } from 'react-svgmt';

import Template5 from '../cal05.svg';
import Template6 from '../holiday_template6.png';
import TitleImage from '../cover_title.svg';
import { ReactComponent as Cal } from '../cal5.svg';
// import { saveAs } from 'file-saver';


const MonthTitle = ({ lang, year, month, classes }) => {
    return (
        <div className={classes("MonthTitleWrapper")}>
            <img src={TitleImage} alt="title" className={classes("monthTitleImage")} />

            {/* <div className={classes("MonthTitle Block")}> */}
            <span className={classes("MonthTitle Label Year")}>{year}</span>
            <span className={classes("MonthTitleLabelYearUnit")}>年</span>
            <span className={classes("MonthTitle Label Month")}>{month}</span>
            {/* </div> */}
        </div>
    );
}

// DOW of the leftmost column.
const firstColumnDOW = 0;
const dowStrsJa = [
    "日",
    "月",
    "火",
    "水",
    "木",
    "金",
    "土",
];
const dowStrsEn = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];

const Component = ({
    holidays,
    year, month, classes,
    lang = "ja"
}) => {

    let svgRef = useRef();

    return (
        <div>
            <button onClick={e => {
                let serializer = new XMLSerializer();
                let svgSource = '<?xml version="1.0" standalone="no"?>\r\n' + serializer.serializeToString(svgRef.current);
                var blob = new Blob([svgSource], { type: "image/svg+xml" });
                // saveAs(blob, "foo.svg");
                alert("saver not installed");

            }} >Download</button>
            <button onClick={e => {

                let txtYear = svgRef.current.getElementById("txtYear");
                txtYear.textContent = 2030;

                let txtMonth = svgRef.current.getElementById("txtMonth");
                txtMonth.textContent = 11;

                let txtDate30 = svgRef.current.getElementById("date30")
                    .childNodes[1];
                txtDate30.textContent = 31;

                // let txtHoliday30 = svgRef.current.getElementById("holiday30")
                //     .childNodes[0];
                svgRef.current.getElementById("holiday30")
                    .childNodes[0].textContent = "これは新正月のあ";
                svgRef.current.getElementById("holiday30")
                    .childNodes[1].textContent = "";

                // document.getElementById("mySvg").getElementById("txtYear").textContent = 2021
                // console.log()                svgRef.current.

                // var els = svgRef.current.getElementsByTagName("text");
                // for (let i = 0; i < els.length; i++) {
                //     els[i].textContent = "hey hey";
                // }

            }} >Edit</button>
            < Cal id="mySvg" ref={svgRef} />
        </div >
    );
}

export default Component;

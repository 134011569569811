import React, { Fragment } from 'react';

import dayjs from 'dayjs';
import EditHolidaysView from './EditHolidaysView';

import { makeStyles } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link, AppBar, Toolbar, Typography } from '@material-ui/core';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase/';
import { connect } from 'react-redux'
import { compose } from 'redux'

// const mapStateToProps = state => {
//   console.log(state);
//   return {
//     allHolidays: state.firestore.ordered.holiday,
//   };
// }
// const mapDispatchToProps = dispatch => {
//   return {
//     // doLogout: bindLogout(dispatch),
//   }
// }

const Component = ({
  match
  // allHolidays
}) => {
  console.log(match.params.fromDate);

  let initialDate = dayjs(new Date(2022, 1 - 1, 1));
  if (match.params.fromDate) {
    initialDate = dayjs(match.params.fromDate);
  }
  // console.log(initialDate);

  return <div>
    < Link href="/cal" target="_blank" > Responsive</Link> <br />
    < Link href="/gb6p" target="_blank" > Graphics B6P</Link> <br />
    < Link href="/gb6" target="_blank" > Graphics B6</Link> <br />
    <Link href="/post" target="_blank">Graphics ポストカードサイズ</Link> <br />
    <Link href="/b6" target="_blank">B6サイズ</Link> <br />
    <Link href="/b6en" target="_blank">B6(English)サイズ</Link> <br />
    <hr />
    <EditHolidaysView numPerPage={7} initialDate={initialDate} />
  </div >;
}

export default Component;

// export default compose(connect(
//   mapStateToProps,
//   mapDispatchToProps),
//   firestoreConnect((props) => {
//     return [
//       {
//         collection: 'holiday',

//         // doc: id,
//         // storeAs: 'pathsResult',
//       }
//     ]
//   }
//   ))(Component);

import React, { Fragment, useState } from 'react';

// import 'App.js';
import EditDayView from './EditDayView';

import { makeStyles } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Link, AppBar, Toolbar, Typography, Card, CardContent, Button, TextField, Input } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase';
import { useFirestore } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'

import dayjs from 'dayjs';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
  datePane: {
    padding: theme.spacing(1),
  },
  holidayPane: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }
}));

const Component = ({
  numPerPage,
  initialDate
}) => {
  const classes = useStyles();

  const [fromDate, setFromDate] = useState(initialDate);
  const history = useHistory();

  const jumpToDateStarting = (newDate) => {
    setFromDate(newDate);
    const url =
      "/edit/" + newDate.format("YYYYMMDD");
    history.push(url);
  }

  const toDate = fromDate.add(numPerPage, 'day');

  let dayPanes = [];
  for (let d = fromDate; d.isBefore(toDate); d = d.add(1, "day")) {
    let formattedDate = d.format("YYYY-MM-DD");
    dayPanes.push(
      <EditDayView key={formattedDate} date={formattedDate} />
    )
  }

  return <div className={classes.root}>
    {dayPanes}
    <Button onClick={(e) => jumpToDateStarting(fromDate.add(-numPerPage, 'day'))} color="primary">Prev day</Button>
    <Button onClick={(e) => jumpToDateStarting(fromDate.add(numPerPage, 'day'))} color="primary">Next day</Button>
  </div >;
}

export default Component;

// export default compose(
//   // withFirestore,
//   connect(
//     mapStateToProps,
//     mapDispatchToProps),
//   firestoreConnect((props) => {
//     return [
//       {
//         collection: 'holiday',
//         limit: 20,
//         // doc: id,
//         // storeAs: 'pathsResult',
//       }
//     ]
//   }
//   ))(Component);

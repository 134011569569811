import React, { Fragment, useState } from 'react';

// import 'App.js';

import AddHolidayView from './AddHolidayView';

import { makeStyles } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Link, AppBar, Toolbar, Typography, Card, CardContent, Button, TextField, Input, Chip, Icon, Radio,
  FormControl, IconButton
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { firestoreConnect } from 'react-redux-firebase'
import firebase from '../firebase';
import { useFirestore } from 'react-redux-firebase'
import { connect, batch } from 'react-redux'
import { compose } from 'redux'

import dayjs from 'dayjs';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
  datePane: {
    padding: theme.spacing(1),
  },
  holidayPane: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }
}));

const mapStateToProps = (state, ownProps) => {
  return {
    allHolidays: state.firestore.ordered[`holiday_${ownProps.date}`],
  };
}
const mapDispatchToProps = dispatch => {
  return {
    // doLogout: bindLogout(dispatch),
  }
}

const HolidayPanel = ({
  holiday,
  makeRepresentative
}) => {
  const classes = useStyles();
  const firestore = useFirestore();

  const nameJa = React.createRef();
  const [isEditing, setEditing] = useState(false);

  const submitEdit = (newName) => {
    firestore.collection("holiday").doc(holiday.id)
      .update({
        nameJa: newName,
      });
    setEditing(false);
  }
  const submitForm = (e) => {
    e.preventDefault();
    submitEdit(nameJa.current.value);
  }

  const deleteHoliday = (e) => {
    e.preventDefault();
    if (window.confirm("Delete holiday " + holiday.originalName + "?")) {
      console.log("delete", holiday.id);
      firestore.collection("holiday").doc(holiday.id).delete();
    }
  }

  return <Paper className={classes.holidayPane}>
    <div>
      <Radio
        checked={holiday.representative}
        onChange={makeRepresentative}
        value="a"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      />

      {holiday.country}

      {holiday.representative &&
        <b>
          ・
          {holiday.representative && holiday.confirmed ? "CONFIRMED" :
            <button onClick={makeRepresentative}>Confirm</button>}
        </b>
      }

      <IconButton arial-label="delete" onClick={deleteHoliday}>
        <DeleteIcon />
      </IconButton>

      {/* {holiday.representative ? "REPRESENTATIVE" :
        <Button variant="contained" color="primary" onClick={(e) => makeRepresentative()}
          startIcon={<Icon>star</Icon>}
        >Pick</Button>}<br /> */}
    </div>
    <div><Typography variant="h6">{holiday.originalName}</Typography></div>
    <form
      onSubmit={submitForm} >

      {!isEditing && <Button onClick={(e) => setEditing(true)} >{holiday.nameJa || "Not Set"}</Button>}
      {isEditing && <Input id="standard-basic" label="日本語名"
        inputRef={nameJa}
        defaultValue={holiday.nameJa || ""}
      > </Input>}
      {isEditing && <Button onClick={(e) => setEditing(false)}>Cancel</Button>}
      {holiday.nameJaHint && <Chip variant="outlined" label={"Suggested:" + holiday.nameJaHint.ja}
        onClick={(e) => submitEdit(holiday.nameJaHint.ja)} />}
    </form>
  </Paper >
};

const Component = ({
  date,
  allHolidays
}) => {
  const classes = useStyles();
  const firestore = useFirestore();

  if (!allHolidays) {
    return <div>No data!</div>;
  }

  const makeRepresentative = (holidayToMakeRepresentative) => {
    let batch = firestore.batch();
    for (let holiday of allHolidays) {
      if (holiday.id === holidayToMakeRepresentative.id) {
        console.log("make ", holiday.country, "newly representative");
        batch.update(firestore.collection("holiday").doc(holiday.id), {
          representative: true,
          confirmed: true,
        });
      } else if (holiday.representative) {
        console.log("make ", holiday.country, "no longer representative");
        batch.update(firestore.collection("holiday").doc(holiday.id), {
          representative: false,
          confirmed: false,
        });
        continue;
      }
    }
    batch.commit();
  };

  let d = dayjs(date);

  // Bring the representative one to the front.
  allHolidays.sort((a, b) => {
    if (a.representative === b.representative) return 0;
    return a.representative && !b.representative ? -1 : 1;
  });

  let holidayPanes = allHolidays.map(h => {
    return <HolidayPanel key={h.id} holiday={h} makeRepresentative={() => makeRepresentative(h)} />;
  });
  return <Paper className={classes.datePane} elevation={3}>
    <Typography variant="h3" component="h3">
      {d.format("YYYY-MM-DD (ddd)")}
    </Typography>
    {holidayPanes}
    <AddHolidayView date={d.format("YYYY-MM-DD")} />
  </Paper>;
}


export default compose(
  // withFirestore,
  connect(
    mapStateToProps,
    mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'holiday',
        // limit: 2,
        where: [
          ['date', '==', props.date],
        ],
        // doc: id,
        storeAs: `holiday_${props.date}`,
      }
    ]
  }
  ))(Component);

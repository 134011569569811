import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/analytics"
import { firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({
});

export default firebase;
